import { SxProps } from '@mui/material';

export const navItemAccordionContainerSX: SxProps = {
	borderTop: '1px solid',
	borderTopColor: 'text.primary',

	'.MuiAccordionSummary-content': {
		display: 'block',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
};
