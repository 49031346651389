/* eslint-disable complexity */

import { DivalMegaMenuSecondLevel } from '@/components/content/Header/parts/MegaMenu/SecondLevel';
import { divalMegaMenuFirstLevelContainerSX } from '@/components/content/Header/parts/MegaMenu/styles/firstLevelContainer';
import { PageLink } from '@/data/Navigation';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, ListItemText, MenuItem, Stack } from '@mui/material';
import { FC, useMemo, useState } from 'react';

export const DivalMegaMenuFirstLevel: FC<{
	tree?: PageLink[];
}> = ({ tree }) => {
	const [selectedMenuItem, setSelectedMenuItem] = useState<PageLink | undefined>();
	const displaySubMenu = useMemo(() => selectedMenuItem?.children?.length, [selectedMenuItem]);

	return tree?.length ? (
		<>
			<Box>
				<Stack direction="column" sx={divalMegaMenuFirstLevelContainerSX(displaySubMenu)}>
					{tree.map((pageLink) => {
						const { label, url, children } = pageLink;
						const isSelected = selectedMenuItem === pageLink;
						return (
							<Box
								key={`${label}${url}`}
								sx={{
									zIndex: 1,
									...(displaySubMenu
										? {
												borderRight: '1px solid',
												borderRightColor: isSelected ? '#f2f2f2' : 'background.main',
											}
										: null),
									...(isSelected
										? {
												'& + div > div': {
													borderColor: 'background.main',
												},
											}
										: null),
									'&:first-of-type > div, &:first-of-type > a': {
										borderTop: '0 none',
									},
								}}
								onMouseEnter={() => setSelectedMenuItem(pageLink)}
							>
								<MenuItem
									component={children?.length ? 'div' : 'a'}
									href={children?.length ? undefined : url}
									sx={{
										width: '250px',
										p: '8px 16px',
										color: isSelected ? 'primary.main' : 'text.secondary',
										borderTop: '1px solid',
										borderColor: isSelected ? 'background.main' : '#e0e0e0',
										backgroundColor: isSelected ? '#f2f2f2' : 'white',
										'.MuiListItemText-root': { textWrap: 'wrap', whiteSpace: 'normal' },
										'.MuiTypography-root': { fontWeight: isSelected ? 600 : 400 },
										'&:hover': {
											backgroundColor: '#f2f2f2',
											color: 'primary.main',
										},
									}}
									data-testid={`header-link-${label}`}
									id={`header-link-${label}`}
								>
									<ListItemText>{label}</ListItemText>
									{children?.length ? <ChevronRightIcon sx={{ ml: 2.5 }} /> : null}
								</MenuItem>
							</Box>
						);
					})}
				</Stack>
			</Box>

			<DivalMegaMenuSecondLevel menu={selectedMenuItem} />
		</>
	) : null;
};
