import { DivalMegaMenuFirstLevel } from '@/components/content/Header/parts/MegaMenu/FirstLevel';
import { divalHeaderNavBarItemSX } from '@/components/content/Header/styles/navBar/divalItem';
import { productsNavBarDropMenuSX } from '@/components/content/Header/styles/navBar/productsDropMenu';
import { useNextRouter } from '@/data/Content/_NextRouter';
import { PageLink } from '@/data/Navigation';
import { Switch } from '@/utils/switch';
import { Button, ClickAwayListener, Stack, Tooltip } from '@mui/material';
import React, { useCallback, useEffect } from 'react';

type Props = {
	tree: PageLink[];
	label: string;
};

export const DivalMegaMenu = React.forwardRef(({ tree, label }: Props) => {
	const router = useNextRouter();
	const [open, setOpen] = React.useState(false);
	const handleToolTip = useCallback(
		(action?: string) => () =>
			setOpen((open) =>
				Switch(action)
					.case('open', () => true)
					.case('close', () => false)
					.defaultTo(() => !open)
			),
		[]
	);
	useEffect(() => {
		if (!router.asPath) return;
		handleToolTip('close')();
	}, [handleToolTip, router.asPath]);
	return (
		<ClickAwayListener onClickAway={handleToolTip('close')}>
			<Stack onMouseLeave={handleToolTip('close')}>
				<Tooltip
					placement="bottom-start"
					open={open}
					onClose={handleToolTip('close')}
					disableFocusListener
					disableHoverListener
					disableTouchListener
					componentsProps={{
						tooltip: {
							sx: productsNavBarDropMenuSX,
						},
					}}
					title={
						tree && tree.length > 0 ? (
							<Stack direction="row">
								<DivalMegaMenuFirstLevel tree={tree} />
							</Stack>
						) : null
					}
				>
					<Button
						onMouseEnter={handleToolTip('open')}
						onTouchStart={handleToolTip('close')}
						sx={divalHeaderNavBarItemSX({ isActive: open })}
					>
						{label}
					</Button>
				</Tooltip>
			</Stack>
		</ClickAwayListener>
	);
});
