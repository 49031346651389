import { SxProps } from '@mui/material';

export const productsNavBarDropMenuSX: SxProps = {
	borderRadius: 0,
	maxWidth: 'calc(100vw - 40px)',
	backgroundColor: 'transparent',
	color: 'background.contrastText',
	m: 0,
	p: 0,
	'@media (hover: hover)': {
		py: 0,
		px: 0,
	},

	'.MuiTooltip-popper[data-popper-placement*="bottom"] > &': {
		'@media (hover: hover)': {
			mt: 0,
			ml: 0,
		},
		'@media (hover: none)': {
			mt: 0,
		},
	},
};
