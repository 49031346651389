/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps } from '@mui/material';

export const headerMiniCartMenuSX: SxProps = {
	width: '300px',
	maxWidth: '100%',
	px: 2,

	'.MuiTableCell-root': {
		padding: '16px 0',
	},
};
