import { useSettings } from '@/data/Settings';
import { IMAGE_RES } from '@/data/constants/catalog';
import { useMemo } from 'react';

export const useDiValImagePath = (originalSrc?: string) => {
	const {
		settings: { imagePath },
	} = useSettings();

	const { thumbnail, thumbnail2x, fullImage, fullImage2x, pdpThumbnail, pdpThumbnail2x } =
		useMemo(() => {
			const src =
				typeof originalSrc === 'string'
					? originalSrc?.replace('/wcsstore', '').replace('/hclstore', '')
					: '';
			return {
				thumbnail: `${imagePath}${IMAGE_RES.thumbnail}${src}`,
				thumbnail2x: `${imagePath}${IMAGE_RES.thumbnail2x}${src}`,
				fullImage: `${imagePath}${IMAGE_RES.fullImage}${src}`,
				fullImage2x: `${imagePath}${IMAGE_RES.fullImage2x}${src}`,
				pdpThumbnail: `${imagePath}${IMAGE_RES.pdpThumbnail}${src}`,
				pdpThumbnail2x: `${imagePath}${IMAGE_RES.pdpThumbnail2x}${src}`,
			};
		}, [originalSrc, imagePath]);

	return {
		thumbnail,
		thumbnail2x,
		fullImage,
		fullImage2x,
		pdpThumbnail,
		pdpThumbnail2x,
	};
};
