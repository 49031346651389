import { SxProps } from '@mui/material';

export const headerMobileDrawerSX: SxProps = {
	maxWidth: '300px',
	minWidth: '250px',
	py: 0.75,
	px: 2,
	'.MuiAccordionSummary-expandIconWrapper': {
		color: 'text.primary',
	},
	'.MuiAccordionSummary-root': {
		paddingRight: 1,
		paddingLeft: 1,
	},
};
