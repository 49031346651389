import { SxProps } from '@mui/material';

export const divalMegaMenuFirstLevelContainerSX = (
	displaySubMenu: number | undefined
): SxProps => ({
	zIndex: 1,

	borderLeftWidth: '1px',
	borderLeftStyle: 'solid',
	borderLeftColor: 'background.main',
	borderBottomWidth: '1px',
	borderBottomStyle: 'solid',
	borderBottomColor: 'background.main',

	...(displaySubMenu
		? { boxShadow: 'rgba(0, 0, 0, 0.05) -2px 3px 2px 0px, rgba(0, 0, 0, 0.03) 0px 2px 1px 0px;' }
		: {
				borderRightWidth: '1px',
				borderRightStyle: 'solid',
				borderRightColor: 'background.main',
				boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 4px 2px, rgba(0, 0, 0, 0.03) 0px 2px 1px 0px;',
			}),
});
