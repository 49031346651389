import { PersonIcon } from '@/components/blocks/Icons/PersonIcon';
import { Linkable } from '@/components/blocks/Linkable';
import { HeaderAccountDropMenu } from '@/components/content/Header/parts/AccountDropMenu';
import { headerAccountContainerSX } from '@/components/content/Header/styles/account/container';
import { headerIconButtonSX, headerItemLinkSX } from '@/components/content/Header/styles/itemLink';
import { headerNavBarDropMenuSX } from '@/components/content/Header/styles/navBar/dropMenu';
import { useLogout } from '@/data/Content/Logout';
import { usePreSelectContract } from '@/data/Content/PreSelectContract';
import { useNextRouter } from '@/data/Content/_NextRouter';
import { useLocalization } from '@/data/Localization';
import { useUser } from '@/data/User';
import { combineSX } from '@/utils/combineSX';
import { Switch } from '@/utils/switch';
import { ExitToAppOutlined } from '@mui/icons-material';
import {
	Breakpoint,
	ClickAwayListener,
	IconButton,
	Stack,
	Tooltip,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';

type Props = {
	mobileBreakpoint: Breakpoint;
};

export const HeaderAccount: FC<Props> = ({ mobileBreakpoint = 'sm' }) => {
	const AccountLabels = useLocalization('SignInPage');
	const HeaderLabels = useLocalization('Header');
	const RouteLocal = useLocalization('Routes');
	const theme = useTheme();
	const router = useNextRouter();
	const isMobile = useMediaQuery(theme.breakpoints.down(mobileBreakpoint));
	const [open, setOpen] = useState(false);
	const { user } = useUser();
	const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
	const welcomeMessage = isLoggedIn ? HeaderLabels.Account.t() : AccountLabels.Title.t();
	const [isPunchOutUser, setIsPunchOutUser] = useState<boolean>(false);
	// preselect contract if currentTradingAgreementIds has more than one
	usePreSelectContract();
	// for CDN caching -- render this on client
	useEffect(() => {
		setIsLoggedIn(() => user?.isLoggedIn ?? false);
		setIsPunchOutUser(() => (user?.x_isPunchOutUser === 'true' ? true : false));
	}, [user]);
	const { handleLogout } = useLogout();

	const handleToolTip = useCallback(
		(action?: string) => () =>
			setOpen((open) =>
				Switch(action)
					.case('open', () => true)
					.case('close', () => false)
					.defaultTo(() => !open)
			),
		[]
	);
	useEffect(() => {
		if (!router.asPath) return;
		handleToolTip('close')();
	}, [handleToolTip, router.asPath]);

	return (
		<ClickAwayListener onClickAway={handleToolTip('close')}>
			<Stack sx={headerAccountContainerSX}>
				<Tooltip
					placement="bottom"
					arrow={true}
					open={open}
					onClose={handleToolTip('close')}
					disableFocusListener
					disableHoverListener
					disableTouchListener
					componentsProps={{
						arrow: {
							sx: {
								'&:before': { border: '1px solid', borderColor: 'background.main' },
								color: 'white',
							},
						},
						tooltip: {
							sx: headerNavBarDropMenuSX,
						},
					}}
					PopperProps={{
						modifiers: [{ name: 'offset', options: { offset: isMobile ? [0, 19] : [8, -1] } }],
					}}
					title={
						isLoggedIn ? (
							<Stack direction="row">
								<HeaderAccountDropMenu />
							</Stack>
						) : null
					}
				>
					<Stack alignItems="center" height="100%">
						{isLoggedIn && isPunchOutUser ? (
							<IconButton
								sx={combineSX([headerItemLinkSX, headerIconButtonSX])}
								onClick={handleLogout}
							>
								<ExitToAppOutlined />
								{isMobile ? null : (
									<Typography variant="body1">{HeaderLabels.AccountPopper.SignOut.t()}</Typography>
								)}
							</IconButton>
						) : isLoggedIn ? (
							<IconButton
								sx={combineSX([headerItemLinkSX, headerIconButtonSX])}
								id="sign-in-or-account-route"
								data-testid="sign-in-or-account-route"
								onClick={handleToolTip()}
							>
								<PersonIcon sx={{ fontSize: { xs: '32px', md: '22px' }, mb: { md: '2px' } }} />
								{isMobile ? null : <Typography sx={{ lineHeight: 1 }}>{welcomeMessage}</Typography>}
							</IconButton>
						) : (
							<Linkable
								component={IconButton}
								sx={combineSX([headerItemLinkSX, headerIconButtonSX])}
								href={RouteLocal.Login.route.t()}
							>
								<>
									<PersonIcon sx={{ fontSize: { xs: '32px', md: '22px' }, mb: { md: '2px' } }} />
									{isMobile ? null : (
										<Typography sx={{ lineHeight: 1 }}>{welcomeMessage}</Typography>
									)}
								</>
							</Linkable>
						)}
					</Stack>
				</Tooltip>
			</Stack>
		</ClickAwayListener>
	);
};
