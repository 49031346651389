/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { Linkable } from '@/components/blocks/Linkable';
import { headerAccountItemSX } from '@/components/content/Header/styles/account/item';
import { headerAccountMenuSX } from '@/components/content/Header/styles/account/menu';
import { useLogout } from '@/data/Content/Logout';
import { useLocalization } from '@/data/Localization';
import { useUser } from '@/data/User';
import { AccountCircle, ExitToAppOutlined } from '@mui/icons-material';
import { Divider, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC } from 'react';

export const HeaderAccountDropMenu: FC = () => {
	const AccountLabels = useLocalization('Header');
	const RouteLocal = useLocalization('Routes');
	const { handleLogout, settings } = useLogout();
	const { user } = useUser();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Stack spacing={1} divider={<Divider />}>
			<Stack divider={<Divider />} sx={headerAccountMenuSX}>
				{isMobile ? (
					<Typography sx={headerAccountItemSX}>
						{AccountLabels.AccountPopper.Welcome.t({
							firstName: user?.firstName ?? '',
							lastName: user?.lastName ?? '',
						})}
					</Typography>
				) : null}
				<Linkable
					href={`/${RouteLocal.Account.route.t()}`}
					id={RouteLocal.Account.route.t()}
					data-testid={RouteLocal.Account.route.t()}
				>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="flex-start"
						spacing={1}
						sx={headerAccountItemSX}
					>
						<AccountCircle />
						<Typography variant="body1">{AccountLabels.AccountPopper.YourAccount.t()}</Typography>
					</Stack>
				</Linkable>
				{settings?.csrSession ? null : (
					<Linkable href="/" onClick={handleLogout}>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="flex-start"
							spacing={1}
							sx={headerAccountItemSX}
						>
							<ExitToAppOutlined />
							<Typography variant="body1">{AccountLabels.AccountPopper.SignOut.t()}</Typography>
						</Stack>
					</Linkable>
				)}
			</Stack>
		</Stack>
	);
};
