import { LinkWrap } from '@/components/blocks/Linkable';
import { ContentRecommendationWithoutWraper } from '@/components/content/ContentRecommendation/ContentRecommendationWithoutWrapper';
import { PageLink } from '@/data/Navigation';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, MenuItem, Typography } from '@mui/material';
import { FC } from 'react';

export const MobileSubNavAccordion: FC<{
	tree?: PageLink[];
}> = ({ tree }) => (
	<>
		{tree?.map(({ label, url, children, identifier }) =>
			children?.length ? (
				<Accordion square={true} disableGutters={true} elevation={0} key={`${label}${url}`}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						sx={{
							paddingLeft: '16px !important',
							paddingRight: '16px !important',
							minHeight: '38px',
							'&.Mui-expanded': { minHeight: '38px' },
							'.MuiAccordionSummary-expandIconWrapper': { p: 0, color: 'text.secondary' },
						}}
					>
						<Typography variant="body1" component="div" sx={{ fontSize: '15px !important' }}>
							{label}
						</Typography>
					</AccordionSummary>
					<AccordionDetails
						sx={{
							borderTop: '1px solid',
							borderColor: 'text.disabled',
							px: 0,
							mx: 1,
							'.MuiMenuItem-root': { minHeight: '38px' },
						}}
					>
						<ContentRecommendationWithoutWraper
							id={`CategoryMenuBefore_${identifier}`}
							properties={{ emsName: `CategoryMenuBefore_${identifier}` }}
						/>
						{children.map(({ label, url }) => (
							<LinkWrap href={url} key={`${label}${url}`}>
								<MenuItem component="a" sx={{ px: 2 }}>
									{label}
								</MenuItem>
							</LinkWrap>
						))}
						<ContentRecommendationWithoutWraper
							id={`CategoryMenuAfter_${identifier}`}
							properties={{ emsName: `CategoryMenuAfter_${identifier}` }}
						/>
					</AccordionDetails>
				</Accordion>
			) : (
				<LinkWrap href={url} key={`${label}${url}`}>
					<MenuItem component="a" sx={{ minHeight: '38px', fontSize: '15px !important' }}>
						{label}
					</MenuItem>
				</LinkWrap>
			)
		)}
	</>
);
