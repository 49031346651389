import { BASE_ADD_2_CART_BODY, addToCartFetcher, useCart } from '@/data/Content/Cart';
import { useNotifications } from '@/data/Content/Notifications';
import { useExtraRequestParameters } from '@/data/Content/_ExtraRequestParameters';
import { useNextRouter } from '@/data/Content/_NextRouter';
import { useLocalization } from '@/data/Localization';
import { useSettings } from '@/data/Settings';
import { TransactionErrorResponse } from '@/data/types/Basic';
import { processError } from '@/data/utils/processError';
import { useCallback, useState } from 'react';
interface Part {
	partNumber: string;
	quantity: string;
}

interface ApiResError {
	errorCode: string;
	errorKey: string;
	errorMessage: string;
	errorParameters: string;
}

export const useQuickOrder = () => {
	const router = useNextRouter();
	const routes = useLocalization('Routes');
	const { settings } = useSettings();
	const params = useExtraRequestParameters();
	const { mutateCart } = useCart();
	const { notifyError } = useNotifications();
	const [resError, setResError] = useState<ApiResError | null>(null);
	const [canSubmit, setCanSubmit] = useState(false);
	const addToCart = useCallback(
		async (data: Part[], setOpen: React.Dispatch<React.SetStateAction<boolean>>) => {
			const orderItem = data;
			const body = { ...BASE_ADD_2_CART_BODY, orderItem };
			try {
				await addToCartFetcher(true)(settings?.storeId ?? '', {}, body, params);
				await mutateCart();
				setOpen(false);
				scrollTo(0, 0);
				router.push(routes.Cart.route.t());
			} catch (e: any) {
				notifyError(processError(e as TransactionErrorResponse));
				setResError({
					errorCode: e?.error?.errors?.[0]?.errorCode,
					errorKey: e?.error?.errors?.[0]?.errorKey,
					errorMessage: e?.error?.errors?.[0]?.errorMessage,
					errorParameters: e?.error?.errors?.[0]?.errorParameters,
				});
				setCanSubmit(true);
			}
		},
		[mutateCart, notifyError, params, router, routes.Cart.route, settings?.storeId]
	);
	return { addToCart, resError, setCanSubmit, canSubmit };
};
