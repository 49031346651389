import { useLocalization } from '@/data/Localization';
import { isEmpty } from 'lodash';
import { FC, useMemo } from 'react';

export const ProductXRef: FC<{ xrefPartNumber: string[] | undefined }> = ({ xrefPartNumber }) => {
	const NLS = useLocalization('productDetail');

	// format the message based on the length of the array
	const message = useMemo(() => {
		if (typeof xrefPartNumber !== 'undefined' && !isEmpty(xrefPartNumber)) {
			if (xrefPartNumber.length === 1) {
				return NLS.XRefSingleMsg.t({ product: xrefPartNumber[0] });
			} else if (xrefPartNumber.length === 2) {
				return NLS.XRefTwoMsg.t({ product1: xrefPartNumber[0], product2: xrefPartNumber[1] });
			} else {
				return NLS.XRefMultipleMsg.t({
					products: xrefPartNumber.slice(0, xrefPartNumber.length - 1).join(', '),
					product: xrefPartNumber[xrefPartNumber.length - 1],
				});
			}
		}
	}, [NLS, xrefPartNumber]);

	return message ? <>{message}</> : null;
};
