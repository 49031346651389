/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { HeaderMiniCartDropMenu } from '@/components/content/Header/parts/MiniCartDropMenu';
import { headerIconButtonSX, headerItemLinkSX } from '@/components/content/Header/styles/itemLink';
import { headerMiniCartContainerSX } from '@/components/content/Header/styles/miniCart/container';
import { headerNavBarDropMenuSX } from '@/components/content/Header/styles/navBar/dropMenu';
import { useCart } from '@/data/Content/Cart';
import { useNotifications } from '@/data/Content/Notifications';
import { useNextRouter } from '@/data/Content/_NextRouter';
import { useLocalization } from '@/data/Localization';
import { useUser } from '@/data/User';
import { combineSX } from '@/utils/combineSX';
import { Switch } from '@/utils/switch';
import { touchMoveListener } from '@/utils/touchMoveListener';
import CloseIcon from '@mui/icons-material/Close';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {
	Badge,
	Breakpoint,
	Button,
	ClickAwayListener,
	Dialog,
	DialogContent,
	IconButton,
	Stack,
	Tooltip,
	Typography,
	useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FC, useCallback, useEffect, useRef, useState } from 'react';

type Props = {
	mobileBreakpoint: Breakpoint;
};

export const HeaderMiniCart: FC<Props> = ({ mobileBreakpoint = 'sm' }) => {
	const router = useNextRouter();
	const CartLabels = useLocalization('MiniCart');
	const { message } = useNotifications();
	const theme = useTheme();
	const { user: { userId } = {} } = useUser();
	const { count: itemCount, mutateCart } = useCart();
	const isMobile = useMediaQuery(theme.breakpoints.down(mobileBreakpoint));
	const [open, setOpen] = useState(false);
	const initialized = useRef<boolean>(false);
	const [count, setCount] = useState<number>(0);

	const handleToolTip = useCallback(
		(action?: string) => () =>
			setOpen((open) =>
				Switch(action)
					.case('open', () => true)
					.case('close', () => false)
					.defaultTo(() => !open)
			),
		[]
	);

	useEffect(() => {
		if (message.isAddToCart) {
			setOpen(true);
		}
	}, [message.isAddToCart]);
	useEffect(() => {
		if (!router.asPath) return;
		handleToolTip('close')();
	}, [handleToolTip, router.asPath]);

	useEffect(() => {
		if (initialized.current) {
			mutateCart();
		} else {
			initialized.current = true;
		}
	}, [mutateCart, userId]);

	// for CDN caching -- render this on client
	useEffect(() => {
		setCount(() => itemCount);
	}, [itemCount]);

	useEffect(() => touchMoveListener(handleToolTip('close')), []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<ClickAwayListener onClickAway={handleToolTip('close')}>
			<Stack sx={headerMiniCartContainerSX}>
				<Tooltip
					id="MiniCartWrapper"
					placement={isMobile ? 'bottom' : 'bottom-end'}
					arrow={true}
					open={open}
					onClose={handleToolTip('close')}
					disableFocusListener
					disableHoverListener
					disableTouchListener
					componentsProps={{
						arrow: {
							sx: {
								'&:before': { border: '1px solid', borderColor: 'background.main' },
								color: 'white',
							},
						},
						tooltip: {
							sx: headerNavBarDropMenuSX,
						},
					}}
					PopperProps={{
						modifiers: [{ name: 'offset', options: { offset: isMobile ? [3, 19] : [8, -1] } }],
					}}
					title={
						<Stack direction="row">
							{isMobile && count > 0 ? (
								<Dialog open={open} onClose={() => setOpen(!open)} fullWidth maxWidth="sm">
									<DialogContent sx={{ '& .MuiStack-root': { width: '100%' } }}>
										<Button
											onClick={() => setOpen(!open)}
											sx={{
												position: 'relative',
												right: '8px',
												top: '8px',
												height: '0 !important',
												width: '100%',
												padding: '0',
												display: 'block',
												'& svg': {
													position: 'absolute',
													right: '0',
												},
											}}
										>
											<CloseIcon color="secondary" />
										</Button>
										<HeaderMiniCartDropMenu />
									</DialogContent>
								</Dialog>
							) : (
								<HeaderMiniCartDropMenu />
							)}
						</Stack>
					}
				>
					<Stack alignItems="center" height="100%">
						{isMobile ? (
							<IconButton
								id="header-mini-cart-icon"
								data-testid="header-mini-cart-icon"
								aria-label={CartLabels.Items.t({ count })}
								sx={combineSX([headerItemLinkSX, headerIconButtonSX])}
								onClick={handleToolTip()}
							>
								<Badge badgeContent={count} color="primary" showZero>
									<ShoppingCartIcon fontSize={'large'} />
								</Badge>
							</IconButton>
						) : (
							<IconButton
								id="header-mini-cart-icon"
								data-testid="header-mini-cart-icon"
								aria-label={CartLabels.Items.t({ count })}
								sx={combineSX([headerItemLinkSX, headerIconButtonSX])}
								onClick={handleToolTip()}
							>
								<ShoppingCartIcon fontSize={'medium'} />
								<Typography component="span" sx={{ lineHeight: 1, whiteSpace: 'nowrap' }}>
									{CartLabels.Items.t({
										count: count.toLocaleString('en-US'),
									})}
								</Typography>
							</IconButton>
						)}
					</Stack>
				</Tooltip>
			</Stack>
		</ClickAwayListener>
	);
};
