import { SxProps } from '@mui/material';

export const navItemEspotSX: SxProps = {
	borderRadius: 0,
	maxWidth: 'calc(100vw - 40px)',
	boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 4px 2px, rgba(0, 0, 0, 0.03) 0px 2px 1px 0px;',
	backgroundColor: 'white',
	color: 'background.contrastText',
	border: '1px solid',
	borderColor: 'background.main',
	borderTop: '0 none',
	m: 0,
	p: 0,
	'@media (hover: hover)': {
		py: 0,
		px: 0,
	},

	'.MuiTooltip-popper[data-popper-placement*="bottom"] > &': {
		'@media (hover: hover)': {
			mt: 0,
			ml: 0,
		},
		'@media (hover: none)': {
			mt: 0,
		},
	},

	'.MuiMenuItem-root': {
		width: '200px',
		p: '8px 16px',
		color: 'text.secondary',
		borderTop: '1px solid',
		borderColor: '#e0e0e0',
		backgroundColor: 'transparent',
		'.MuiListItemText-root': { textWrap: 'wrap', whiteSpace: 'normal' },
		'&:hover': {
			backgroundColor: '#f2f2f2',
			color: 'primary.main',
			borderColor: 'background.main',
			'.MuiTypography-root': { fontWeight: 600 },
			'& + .MuiMenuItem-root': {
				borderColor: 'background.main',
			},
		},
		'&:first-of-type': {
			borderTop: '0 none',
		},
	},
};
