import { SxProps, Theme } from '@mui/material';

export const headerItemLinkSX: SxProps<Theme> = (theme) => ({
	height: '100% !important',
	color: 'inherit',
	fontSize: '20px',
	textAlign: { md: 'center' },
	lineHeight: { md: 1 },
	cursor: 'pointer',
	'&:hover, &:active, &:focus': {
		opacity: { xs: 0.8, md: 1 },
	},
	[theme.breakpoints.up('md')]: {
		borderRadius: 0,
		position: 'relative',
		zIndex: 0,

		'.MuiTouchRipple-root': {
			transform: 'skewX(-10deg) translateX(-5px)',
			'.MuiTouchRipple-child': {
				borderRadius: 0,
			},
		},

		':before': {
			content: '""',
			display: 'block',
			height: '100%',
			width: '100%',
			position: 'absolute',
			left: 0,
			zIndex: '-1',
			transform: 'skewX(-10deg)',
			backgroundColor: 'background.dark',
		},

		'&:hover, &:active, &:focus': {
			textDecoration: 'none',
			color: 'inherit',
			':before': {
				backgroundColor: 'background.main',
			},
		},
	},
});

export const headerIconButtonSX: SxProps = {
	display: 'flex',
	flexDirection: 'column',
	p: { xs: 0.25, sm: 1, md: '0 20px' },
};

export const headerQuickOrderButtonSX: SxProps<Theme> = (theme) => ({
	display: 'flex',
	flexDirection: 'column',
	[theme.breakpoints.up('md')]: {
		lineHeight: 1.2,
		':before': {
			left: '-6px',
			width: 'calc(100% + 12px)',
		},
	},
});
