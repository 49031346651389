import { ContentItemProperties } from '@/data/types/Slot';

const contentItems: ContentItemProperties[] = [
	{ emsName: 'HeaderStoreLogo' },
	{ emsName: 'HeaderTop' },
	{ emsName: 'DiValHeaderBanner' },
	{ emsName: 'HeaderNavLink1' },
	{ emsName: 'HeaderNavLink2' },
	{ emsName: 'HeaderNavLink3' },
	{ emsName: 'HeaderNavLink4' },
	{ emsName: 'HeaderNavLink5' },
];
export default contentItems;
