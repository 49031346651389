import { RenderContent } from '@/components/blocks/RenderContent';
import { useContentRecommendation } from '@/data/Content/ContentRecommendation';
import { ID } from '@/data/types/Basic';
import { WidgetProperties } from '@/data/types/Slot';
import { FC } from 'react';

const emptyProperties = {} as WidgetProperties;

export const ContentRecommendationWithoutWraper: FC<{ id: ID; properties?: WidgetProperties }> = ({
	id: _id,
	properties = emptyProperties,
}) => {
	const { emsName = '' } = properties;
	const { data, loading } = useContentRecommendation(emsName);

	return !loading && data ? (
		<>
			{data?.map((content) => (
				<RenderContent key={`${content.id}${content.contentId}`} content={content} />
			))}
		</>
	) : null;
};
