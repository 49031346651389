import { Img } from '@/components/blocks/MaterialImage';
import { productImageBaseSX } from '@/components/blocks/ProductImage/styles/base';
import { productImageSelectedSX } from '@/components/blocks/ProductImage/styles/selected';
import { productImageThumbnailSX } from '@/components/blocks/ProductImage/styles/thumbnail';
import { useDiValImagePath } from '@/data/Content/DiValImagePath';
import { combineSX } from '@/utils/combineSX';
import { SxProps, Theme } from '@mui/material';
import React, { useMemo } from 'react';

type Props = {
	src: string;
	alt: string;
	isThumbnail?: boolean;
	isSelected?: boolean;
	sx?: SxProps<Theme>;
};

export const ProductImage: React.FC<Props> = ({ src, alt, isSelected, isThumbnail, sx }) => {
	const { fullImage, fullImage2x, pdpThumbnail, pdpThumbnail2x } = useDiValImagePath(src);

	const { image, image2x } = useMemo(() => {
		if (isThumbnail) {
			return { image: pdpThumbnail, image2x: pdpThumbnail2x };
		} else {
			return { image: fullImage, image2x: fullImage2x };
		}
	}, [fullImage, fullImage2x, isThumbnail, pdpThumbnail, pdpThumbnail2x]);

	return src ? (
		<Img
			sx={combineSX([
				productImageBaseSX,
				isThumbnail && productImageThumbnailSX,
				isSelected && productImageSelectedSX,
				sx,
			])}
			src={image}
			srcSet={`${image2x} 2x`}
			alt={alt}
		/>
	) : null;
};
