/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */
import { useNextRouter } from '@/data/Content/_NextRouter';
import { getSettings, useSettings } from '@/data/Settings';
import { Cache } from '@/data/types/Cache';
import { getClientSideCommon } from '@/data/utils/getClientSideCommon';
import { getServerSideCommon } from '@/data/utils/getServerSideCommon';
import { expand, shrink } from '@/data/utils/keyUtil';
import { error as logError } from '@/data/utils/loggerUtil';
import { customExternalInventory } from 'integration/generated/custom';
import { ComDivalCommerceRestExternalcontractHandlerGetInvListByPartNumbersResponse } from 'integration/generated/custom/data-contracts';
import { isEmpty } from 'lodash';
import { GetServerSidePropsContext } from 'next';
import useSWR, { unstable_serialize as unstableSerialize } from 'swr';

export const DATA_KEY_EXTERNAL_INVENTORY = 'ExternalInventory';
export const fetcher =
	(pub: boolean, context?: GetServerSidePropsContext) =>
	async (
		storeId: string,
		query: any = {}
	): Promise<
		ComDivalCommerceRestExternalcontractHandlerGetInvListByPartNumbersResponse | undefined
	> => {
		try {
			const res = (await customExternalInventory(pub).getInvListByPartNumbers(
				storeId,
				query
			)) as ComDivalCommerceRestExternalcontractHandlerGetInvListByPartNumbersResponse;
			return res;
		} catch (error) {
			if (pub) {
				throw error;
			}

			logError(context?.req, 'ExternalInventory: fetcher: error: %o', error);
			return undefined;
		}
	};

export const getExternalInventory = async (
	cache: Cache,
	partNumbers: string | undefined,
	context: GetServerSidePropsContext
): Promise<
	{
		key: string;
		value: ComDivalCommerceRestExternalcontractHandlerGetInvListByPartNumbersResponse;
	}[]
> => {
	const settings = await getSettings(cache, context);
	const { storeId } = getServerSideCommon(settings, context);
	const props = { storeId, partNumbers };
	const key = unstableSerialize([shrink(props), DATA_KEY_EXTERNAL_INVENTORY]);
	const value = cache.get(key) || fetcher(false, context)(storeId, { partNumbers });
	cache.set(key, value);
	return await value;
};

export const useExternalInventory = (partNumbers: string | undefined) => {
	const { settings } = useSettings();
	const router = useNextRouter();
	const { storeId } = getClientSideCommon(settings, router);
	const { data, error } = useSWR(
		storeId && !isEmpty(partNumbers)
			? [shrink({ storeId, partNumbers }), DATA_KEY_EXTERNAL_INVENTORY]
			: null,
		async ([props]) => {
			const expanded = expand<Record<string, any>>(props);
			const { storeId, partNumbers } = expanded;
			return fetcher(true)(storeId, { partNumbers });
		}
	);
	return {
		data,
		loading: !error && !data,
		error,
	};
};
