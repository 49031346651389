import { SxProps, Theme } from '@mui/material';

export const divalHeaderSearchSX: SxProps<Theme> = (theme) => ({
	width: { xs: '100%', md: '340px' },
	maxWidth: { md: '600px' },
	flexGrow: { md: 1 },

	/* clears the 'X' from Chrome */
	'input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-results-button,input[type = "search"]::-webkit-search-results-decoration':
		{ display: 'none' },

	'input::placeholder': {
		color: 'rgba(0, 0, 0, 0.65)',
	},

	'.MuiOutlinedInput-input.MuiAutocomplete-input': {
		backgroundColor: 'transparent',
		'&::-webkit-input-placeholder': {
			opacity: 0.8,
		},
	},

	'.MuiInputBase-root.MuiInputBase-sizeSmall': {
		p: 0,
		height: 'auto',
		backgroundColor: 'text.header',
		backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: '42px 100%',
		backgroundPosition: 'right',
		'.MuiInputBase-input': {
			height: '40px',
			p: '0 16px',
		},
	},
	'.MuiOutlinedInput-notchedOutline': {
		border: '0 none',
	},
	'.MuiInputAdornment-root': {
		m: 0,
		'.MuiButtonBase-root': {
			borderRadius: '4px',
			'.MuiTouchRipple-root .MuiTouchRipple-child': {
				borderRadius: '4px',
			},
		},
	},
	fieldset: {
		boxShadow: 'none !important',
	},
});
