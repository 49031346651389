/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */
import { useNextRouter } from '@/data/Content/_NextRouter';
import { useSettings } from '@/data/Settings';
import { getClientSideCommon } from '@/data/utils/getClientSideCommon';
import { expand, shrink } from '@/data/utils/keyUtil';
import { customCatentryRef } from 'integration/generated/custom';
import { CatentryRef } from 'integration/generated/custom/CatentryRef';
import { ComDivalCommerceRestCatalogHandlerCatEntryRefHandlerGetCatentryRefResponse } from 'integration/generated/custom/data-contracts';
import useSWR from 'swr';

const DATA_KEY = 'CustomProductXRef';

type Props = Parameters<CatentryRef['getCatentryRef']>;
const fetcher =
	(pub: boolean) =>
	async (
		storeId: Props[0],
		partNumber: Props[1]
	): Promise<
		ComDivalCommerceRestCatalogHandlerCatEntryRefHandlerGetCatentryRefResponse | undefined
	> =>
		await customCatentryRef(pub).getCatentryRef(storeId, partNumber);

export const useProductXRef = (partNumber: string | undefined) => {
	const { settings } = useSettings();
	const router = useNextRouter();
	const { storeId } = getClientSideCommon(settings, router);

	const { data, error } = useSWR(
		storeId && partNumber ? [shrink({ storeId, partNumber }), DATA_KEY] : null,
		async ([props]) => {
			const expanded = expand<Record<string, any>>(props);
			const { storeId, partNumber } = expanded;
			return fetcher(true)(storeId, partNumber);
		}
	);

	return {
		data,
		loading: !error && !data,
		error,
	};
};
