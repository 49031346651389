import { MobileNavItemEspot } from '@/components/content/Header/parts/MobileDrawer/MobileNavItemEspot';
import { MobileSubNavAccordion } from '@/components/content/Header/parts/MobileDrawer/MobileSubNavAccordion';
import { headerMobileDrawerSX } from '@/components/content/Header/styles/mobileDrawer';
import { navItemAccordionContainerSX } from '@/components/content/Header/styles/navBar/navItemAccordionContainer';
import { useNextRouter } from '@/data/Content/_NextRouter';
import { useLocalization } from '@/data/Localization';
import { useNavigation } from '@/data/Navigation';
import { ID } from '@/data/types/Basic';
import { ContentItemProperties } from '@/data/types/Slot';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Drawer,
	IconButton,
	Stack,
	Typography,
} from '@mui/material';
import { FC, useEffect } from 'react';

export const DivalHeaderMobileDrawer: FC<{
	toggleDrawer: (open?: boolean) => () => void;
	open: boolean;
	navEspots: ContentItemProperties[];
	id: ID;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ toggleDrawer, open, navEspots, id, setOpen }) => {
	const { allCategories } = useNavigation();
	const router = useNextRouter();
	useEffect(() => {
		if (!router.asPath) return;
		toggleDrawer(false)();
	}, [router.asPath, toggleDrawer]);
	const HeaderLabels = useLocalization('Header');

	return allCategories ? (
		<Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
			<Box sx={headerMobileDrawerSX} role="presentation">
				<Stack alignItems="flex-end">
					<IconButton
						aria-label="close menu"
						onClick={toggleDrawer()}
						sx={{ color: 'text.primary' }}
					>
						<CloseIcon />
					</IconButton>
				</Stack>
				<Stack direction="column" mt={0.75}>
					<Accordion square={true} disableGutters={true} elevation={0}>
						<AccordionSummary expandIcon={<ExpandMoreIcon />} sx={navItemAccordionContainerSX}>
							<Typography variant="h6" component="div" sx={{ fontSize: '15px !important' }}>
								{allCategories.label}
							</Typography>
						</AccordionSummary>
						<AccordionDetails sx={{ borderTop: '1px solid', borderColor: 'text.primary', px: 0 }}>
							<MobileSubNavAccordion tree={allCategories.children} />
						</AccordionDetails>
					</Accordion>
					{navEspots.map((properties) => (
						<MobileNavItemEspot
							key={properties.emsName}
							id={`${id}-${properties.emsName}`}
							properties={properties}
						/>
					))}
					<Stack
						px={1}
						pt={2}
						spacing={1}
						sx={{
							borderTop: '1px solid',
							borderColor: 'text.primary',
							'.MuiTypography-root': { fontSize: '15px' },
						}}
					>
						<Button variant="contained" color="secondary" onClick={() => setOpen(true)}>
							{HeaderLabels.QuickShop.t()}
						</Button>
					</Stack>
				</Stack>
			</Box>
		</Drawer>
	) : null;
};
