import { ProgressIndicator } from '@/components/blocks/ProgressIndicator';
import { RenderContent } from '@/components/blocks/RenderContent';
import { navItemAccordionContainerSX } from '@/components/content/Header/styles/navBar/navItemAccordionContainer';
import { useContentRecommendation } from '@/data/Content/ContentRecommendation';
import { ID } from '@/data/types/Basic';
import { WidgetProperties } from '@/data/types/Slot';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { FC } from 'react';

const emptyProperties = {} as WidgetProperties;

export const MobileNavItemEspot: FC<{ id: ID; properties?: WidgetProperties }> = ({
	id: _id,
	properties = emptyProperties,
}) => {
	const { emsName = '' } = properties;
	const { data, loading, title } = useContentRecommendation(emsName);

	return loading ? (
		<ProgressIndicator />
	) : title && data ? (
		<Accordion square={true} disableGutters={true} elevation={0}>
			<AccordionSummary expandIcon={<ExpandMoreIcon />} sx={navItemAccordionContainerSX}>
				<Typography variant="h6" component="div" sx={{ fontSize: '15px !important' }}>
					{title?.map((content) => (
						<RenderContent key={`${content.id}${content.contentId}`} content={content} />
					))}
				</Typography>
			</AccordionSummary>
			<AccordionDetails
				sx={{
					borderTop: '1px solid',
					borderColor: 'text.primary',
					px: 0,
					color: 'text.secondary',
					'.MuiButtonBase-root': { minHeight: '38px' },
					'.MuiTypography-root': { fontSize: '15px' },
				}}
			>
				{data?.map((content) => (
					<RenderContent key={`${content.id}${content.contentId}`} content={content} />
				))}
			</AccordionDetails>
		</Accordion>
	) : null;
};
