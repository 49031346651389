import { SxProps } from '@mui/material';

export const headerSearchPopperSX: SxProps = () => ({
	marginTop: '1px',

	'.MuiAutocomplete-groupLabel': {
		textTransform: 'uppercase',
		fontWeight: '700',
		borderBottomWidth: '1px',
		borderBottomStyle: 'solid',
		borderBottomColor: 'text.primary',
		margin: '0 16px 10px 16px',
		padding: '0 !important',
	},

	'.MuiAutocomplete-groupUl': {
		px: 1,

		'.MuiAutocomplete-option': {
			px: 1,
			borderRadius: 1,
			minHeight: 'auto',
		},
	},
});
