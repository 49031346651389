/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps } from '@mui/material';

export const headerAccountMenuSX: SxProps = {
	minWidth: '250px',
	maxWidth: '100%',
	px: 2,
	color: 'text.primary',
};
