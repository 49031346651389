/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps } from '@mui/material';

export const divalHeaderNavBarItemSX = ({ isActive = false }: { isActive?: boolean }): SxProps => ({
	fontSize: '1.025em !important',
	cursor: 'pointer !important',
	'&.MuiButton-text': {
		p: 2,
		height: '56px',
		borderTop: '3px solid',
		borderBottom: '3px solid',
		borderTopColor: 'background.dark',
		backgroundColor: isActive ? 'background.main' : 'transparent',
		borderBottomColor: isActive ? 'background.main' : 'background.dark',
		color: 'text.expandedMenu',
		'&:hover': {
			backgroundColor: 'background.main',
			borderBottomColor: 'background.main',
		},
		fontSize: 16,
		lineHeight: 1,
		borderRadius: 0,
		'&:is(a):not([href])': {
			cursor: 'default',
		},
		'> a': {
			color: 'text.expandedMenu',
			'&:hover': {
				textDecoration: 'none',
			},
		},
	},
});
