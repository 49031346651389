import { ContentRecommendationWithoutWraper } from '@/components/content/ContentRecommendation/ContentRecommendationWithoutWrapper';
import { divalMegaMenuSecondLevelContainerSX } from '@/components/content/Header/parts/MegaMenu/styles/secondLevelContainer';
import { useLocalization } from '@/data/Localization';
import { PageLink } from '@/data/Navigation';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Grid, Link, Stack, Typography } from '@mui/material';
import { FC } from 'react';

export const DivalMegaMenuSecondLevel: FC<{
	menu?: PageLink;
}> = ({ menu }) => {
	const tree = menu?.children;
	const HeaderLabels = useLocalization('Header');

	return tree?.length ? (
		<Stack spacing={2} direction="column" sx={divalMegaMenuSecondLevelContainerSX}>
			<Stack direction="row" spacing={2} alignItems="baseline">
				<Typography variant="h5" component="h3" color="text.main" sx={{ lineHeight: '1rem' }}>
					{menu?.label}
				</Typography>
				<Link href={menu?.url} variant="h6" sx={{ textTransform: 'uppercase', lineHeight: '1rem' }}>
					{HeaderLabels.ShopAll.t()}
					<ChevronRightIcon sx={{ height: '20px', width: '20px', mb: '-3px' }} />
				</Link>
			</Stack>
			<Grid container>
				<ContentRecommendationWithoutWraper
					id={`CategoryMenuBefore_${menu?.identifier}`}
					properties={{ emsName: `CategoryMenuBefore_${menu?.identifier}` }}
				/>
				{tree.map(({ label, url }) => (
					<Grid item xs={6} key={`${label}${url}`}>
						<Link href={url} variant="body1">
							<ChevronRightIcon sx={{ height: '16px', width: '16px', mb: '-3px' }} />
							{label}
						</Link>
					</Grid>
				))}
				<ContentRecommendationWithoutWraper
					id={`CategoryMenuAfter_${menu?.identifier}`}
					properties={{ emsName: `CategoryMenuAfter_${menu?.identifier}` }}
				/>
			</Grid>
		</Stack>
	) : null;
};
