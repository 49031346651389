import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';

import { useQuickOrder } from '@/data/Content/QuickOrder';
import { Add, Remove } from '@mui/icons-material';

interface Part {
	partNumber: string;
	quantity: string;
}

interface ModalProps {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MAX_ROWS = 12;

const QuickOrder: FC<ModalProps> = ({ open, setOpen }) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const [rows, setRows] = useState<Part[]>([
		{ partNumber: '', quantity: '1' },
		{ partNumber: '', quantity: '1' },
		{ partNumber: '', quantity: '1' },
	]);
	const { addToCart, resError, canSubmit, setCanSubmit } = useQuickOrder();
	const [error, setError] = useState('');

	useEffect(() => {
		if (resError?.errorParameters === '') {
			setError(resError?.errorMessage);
			setTimeout(() => {
				setError('');
			}, 5000);
		}
	}, [resError]);
	const handleAddRow = () => {
		if (rows.length < MAX_ROWS) {
			setRows([...rows, { partNumber: '', quantity: '1' }]);
		}
	};

	const handleRemoveRow = (index: number) => {
		const newRows = rows.filter((_, rowIndex) => rowIndex !== index);
		setRows(newRows);
		checkSubmitButton(newRows);
	};

	const handleInputChange = (index: number, field: keyof Part, value: string | number) => {
		const newRows = rows.map((row, rowIndex) =>
			rowIndex === index ? { ...row, [field]: value.toString() } : row
		);
		setRows(newRows);
		checkSubmitButton(newRows);
	};

	const checkSubmitButton = (rows: Part[]) => {
		const isAnyPartNumberFilled = rows.some((row) => row.partNumber.trim() !== '');
		setCanSubmit(isAnyPartNumberFilled);
	};

	const handleSubmit = () => {
		setCanSubmit(false);
		const filteredData = rows.filter((item) => item.partNumber !== '');
		addToCart(filteredData, setOpen);
	};

	return (
		<Dialog open={open} onClose={() => setOpen(!open)} fullWidth maxWidth="sm">
			<DialogTitle>
				{'Quick Order'}
				{error ? <Typography sx={{ color: 'textError.error' }}>{error}</Typography> : null}
			</DialogTitle>

			<DialogContent>
				{rows.map((row, index) => (
					<Grid container spacing={2} key={index} sx={{ padding: '10px 0' }}>
						<Grid item xs={6}>
							<TextField
								label="Part Number"
								value={row.partNumber}
								onChange={(e) =>
									handleInputChange(index, 'partNumber', e.target.value.trim().toUpperCase())
								}
								fullWidth
							/>
							{resError?.errorParameters !== '' && resError?.errorParameters === row.partNumber ? (
								<Typography sx={{ color: 'textError.error' }}>{resError?.errorMessage}</Typography>
							) : null}
						</Grid>
						<Grid item xs={3}>
							<TextField
								label="Quantity"
								type="text"
								value={row.quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
								onChange={(e) => {
									const rawValue = e.target.value.replace(/,/g, '');
									const numericValue = rawValue.replace(/[^0-9]/g, '');
									const parsedValue = parseInt(numericValue, 10) || 1;
									handleInputChange(index, 'quantity', parsedValue);
								}}
								fullWidth
							/>
						</Grid>
						<Grid item xs={2}>
							<Button
								variant="outlined"
								color="secondary"
								startIcon={<Remove />}
								onClick={() => handleRemoveRow(index)}
								disabled={rows.length <= 1}
								sx={{ mt: 3, '& span': { marginRight: '0' } }}
							>
								{!isMobile ? 'Remove' : null}
							</Button>
						</Grid>
					</Grid>
				))}
				<Button
					variant="contained"
					startIcon={<Add />}
					onClick={handleAddRow}
					disabled={rows.length >= MAX_ROWS}
					sx={{ mt: 2 }}
				>
					{'Add More'}
				</Button>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" color="secondary" onClick={() => setOpen(!open)}>
					{'Cancel'}
				</Button>
				<Button variant="contained" onClick={handleSubmit} disabled={!canSubmit}>
					{'Submit'}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default QuickOrder;
