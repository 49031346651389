/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { divalHeaderSearchSX } from '@/components/content/Header/styles/divalSearch';
import { headerSearchPopperSX } from '@/components/content/Header/styles/search/searchPopper';
import { useSearchNavigation } from '@/data/Content/SearchNavigation';
import { useLocalization } from '@/data/Localization';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, IconButton, InputAdornment, TextField } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';

type Option = {
	label: string;
	href?: string;
	identifier: string;
};

export const HeaderSearch: FC<{ mobile?: boolean }> = ({ mobile }) => {
	const { searchValue, suggest, onInputChange, onSubmit, inputValue, clearSearchTerm } =
		useSearchNavigation();
	const SearchNLS = useLocalization('SearchBar');
	const uniqueId = `type-ahead-site-search-${mobile ? 'mobile' : 'desktop'}`;

	const getDisplayTitle = useCallback(
		(identifier: string | undefined) => {
			if (identifier === 'Keyword') {
				return SearchNLS.KeywordTitle.t();
			} else if (identifier === 'Category') {
				return SearchNLS.CategoryTitle.t();
			} else if (identifier === 'Product') {
				return SearchNLS.ProductTitle.t();
			} else if (identifier) {
				return identifier;
			} else {
				return '';
			}
		},
		[SearchNLS.CategoryTitle, SearchNLS.KeywordTitle, SearchNLS.ProductTitle]
	);

	const options = useMemo<Option[]>(
		() =>
			suggest
				.map(({ identifier, entry }) => {
					const displayTitle = getDisplayTitle(identifier);
					return identifier && entry
						? entry?.map(({ label, href }) => ({
								label,
								href,
								identifier: displayTitle,
							})) || []
						: [];
				})
				.flat(1),
		[getDisplayTitle, suggest]
	);

	return (
		<Autocomplete
			freeSolo
			disableClearable
			onChange={(_, item) => onSubmit(typeof item === 'string' ? { label: item } : item)}
			inputValue={inputValue}
			onInputChange={onInputChange}
			groupBy={({ identifier }) => identifier}
			options={options}
			sx={divalHeaderSearchSX}
			id={uniqueId}
			componentsProps={{
				paper: {
					sx: headerSearchPopperSX,
				},
			}}
			renderInput={({ inputProps, ...params }) => (
				<TextField
					inputProps={{ ...inputProps, 'data-testid': uniqueId }}
					{...params}
					InputProps={{
						...params.InputProps,
						placeholder: SearchNLS.SearchField.t(),
						slotProps: {
							input: { role: undefined, 'aria-expanded': undefined },
						},
						size: 'small',
						type: 'search',
						onKeyDown: (e) => {
							if (e.code === 'Enter' && e.currentTarget.value) {
								onSubmit({ label: e.currentTarget.value });
							}
						},
						endAdornment: (
							<InputAdornment position="end">
								{options?.length ? (
									<IconButton
										aria-label={SearchNLS.Clear.t()}
										onClick={clearSearchTerm}
										sx={{ marginRight: 0.75, p: 0.5 }}
									>
										<CloseIcon />
									</IconButton>
								) : null}
								<IconButton
									aria-label={SearchNLS.SearchField.t()}
									onClick={() => onSubmit({ label: searchValue })}
									edge="end"
									sx={{ m: 0, color: 'white' }}
								>
									<SearchIcon />
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			)}
		/>
	);
};
