/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { useExtraRequestParameters } from '@/data/Content/_ExtraRequestParameters';
import { useNextRouter } from '@/data/Content/_NextRouter';
import { useLocalization } from '@/data/Localization';
import { getContractIdParamFromContext, useSettings } from '@/data/Settings';
import { useUser } from '@/data/User';
import { usePageDataFromId } from '@/data/_PageDataFromId';
import { TYPE_AHEAD_DELAY } from '@/data/config/TYPE_AHEAD_DELAY';
import { getClientSideCommon } from '@/data/utils/getClientSideCommon';
import { getProductListQueryParameters } from '@/data/utils/getProductListQueryParameters';
import { laggyMiddleWare } from '@/data/utils/laggyMiddleWare';
import { error as logError } from '@/data/utils/loggerUtil';
import { customCatalogEntryExternal } from 'integration/generated/custom';
import { CatalogEntryExternal } from 'integration/generated/custom/CatalogEntryExternal';
import { querySiteContentResource } from 'integration/generated/query';
import { SiteContentResource } from 'integration/generated/query/SiteContentResource';
import { CommonSuggestions } from 'integration/generated/query/data-contracts';
import { debounce } from 'lodash';
import { GetServerSidePropsContext } from 'next';
import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';

type CustomUrlProps = Parameters<CatalogEntryExternal['getSeoUrlByPartNumber']>;
const customUrlFetcher =
	(pub: boolean) => async (storeId: CustomUrlProps[0], partNumber: CustomUrlProps[1]) =>
		await customCatalogEntryExternal(pub).getSeoUrlByPartNumber(storeId, partNumber);

type Props = Parameters<SiteContentResource['findSuggestions']>;
type Query = Omit<Props[1], 'suggestType'> & {
	suggestType?: string[]; // Spec wrongly requires string, when it should be an array of strings, requiring this override
};

type SuggestionView = {
	identifier?: string;
	entry: EntryView[];
};
type EntryView = {
	label: string;
	href?: string;
};

const dataMap = (data?: CommonSuggestions): SuggestionView[] =>
	data?.suggestionView
		?.map(({ identifier, entry }) => ({
			identifier,
			entry:
				entry?.map(({ fullPath, term, name, seo }: any) => ({
					label: fullPath || term || name,
					...(seo ? seo : {}),
				})) || [],
		}))
		.filter(({ entry }) => entry.length > 0) || [];

const fetcher =
	(pub: boolean, context?: GetServerSidePropsContext) =>
	async ({
		storeId,
		query,
		params = {},
	}: {
		storeId: Props[0];
		query: Query;
		params: Props[2];
	}): Promise<ReturnType<SiteContentResource['findSuggestions']> | void> => {
		try {
			const data = await querySiteContentResource(pub).findSuggestions(
				storeId,
				query as Props[1],
				params
			);
			return data;
		} catch (error) {
			logError(context?.req, 'SearchNavigation: fetcher: error: %o', error);
		}
	};

const EMPTY_SUGGESTIONS = {};
export const useSearchNavigation = () => {
	const { settings } = useSettings();
	const { user } = useUser();
	const SearchLocalization = useLocalization('Routes').Search;
	const router = useNextRouter();
	const { query } = router;
	const params = useExtraRequestParameters();
	const filteredParams = useMemo(() => getProductListQueryParameters(query), [query]);
	const [searchValue, setSearchValue] = useState<string>(
		() => filteredParams.searchTerm?.toString().trim() || ''
	);
	const [inputValue, setInputValue] = useState<string>(
		() => filteredParams.searchTerm?.toString().trim() || ''
	);
	const { storeId, langId } = getClientSideCommon(settings, router);
	const { data: pageData } = usePageDataFromId();

	const { data = EMPTY_SUGGESTIONS } = useSWR(
		storeId && searchValue !== '' && searchValue.length > 1
			? [
					{
						storeId,
						query: {
							langId,
							suggestType: ['Keyword', 'Category', 'Product'],
							term: searchValue,
							...getContractIdParamFromContext(user?.context),
						},
						params: {},
					},
				]
			: null,
		async ([props]) => fetcher(true)({ ...props, params }),
		{ use: [laggyMiddleWare] }
	);
	const suggest = useMemo(() => (searchValue.length > 1 ? dataMap(data) : []), [data, searchValue]);

	const onSubmit = ({ label, href }: EntryView) => {
		const redirect = () => {
			// OOB behavior
			router.push(
				href
					? { pathname: href }
					: {
							pathname: `/${SearchLocalization.route.t()}`,
							query: { searchTerm: encodeURIComponent(label) },
						},
				undefined,
				{
					shallow: typeof pageData === 'undefined' ? false : true,
				}
			);
		};

		if (label.trim() === '') {
			return;
		}

		// custom search
		if (!href) {
			customUrlFetcher(true)(storeId, label)
				.then((res) => {
					if (res.seoURL) {
						router.push({ pathname: res.seoURL }, undefined, { shallow: true });
					} else {
						redirect();
					}
				})
				.catch(() => {
					redirect();
				});
		} else {
			redirect();
		}
	};

	/**
	 * @deprecated use onInputChange instead.
	 */
	const onChange = (newValue: string) => setSearchValue(newValue);

	// const onInputChange = debounce((_, value) => setSearchValue(value), TYPE_AHEAD_DELAY);
	const updateSearchValue = debounce((value) => setSearchValue(value), TYPE_AHEAD_DELAY);

	const onInputChange = (_: SyntheticEvent<Element, Event>, value: string) => {
		setInputValue(value);
		updateSearchValue(value);
	};

	const clearSearchTerm = () => {
		setInputValue('');
		setSearchValue('');
	};

	useEffect(() => {
		setSearchValue(filteredParams.searchTerm?.toString().trim() || '');
	}, [filteredParams.searchTerm]);

	return {
		suggest,
		searchValue,
		/** @deprecated use onInputChange instead. */
		onChange,
		onInputChange,
		onSubmit,
		inputValue,
		clearSearchTerm,
	};
};
