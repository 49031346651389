/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps } from '@mui/material';

export const headerNavBarDropMenuSX: SxProps = {
	borderRadius: '0 0 6px 6px',
	maxWidth: 'calc(100vw - 40px)',
	boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 4px 2px, rgba(0, 0, 0, 0.03) 0px 2px 1px 0px;',
	backgroundColor: 'white',
	color: 'background.contrastText',
	border: '1px solid',
	borderColor: 'background.main',
	m: 0,
	p: 0,
	'@media (hover: hover)': {
		py: 0,
		px: 0,
	},

	'.MuiTooltip-popper[data-popper-placement*="bottom"] > &': {
		'@media (hover: hover)': {
			mt: 0,
			ml: 0,
		},
		'@media (hover: none)': {
			mt: 0,
		},
	},
};
