import { SxProps } from '@mui/material';

export const headerNavBarContainerSX: SxProps = {
	borderRadius: 0,
	boxShadow: 2,
	backgroundColor: 'background.dark',
	minHeight: '56px',
	display: 'flex',
	alignItems: 'center',
};
