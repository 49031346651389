import { DivalMegaMenu } from '@/components/content/Header/parts/MegaMenu';
import { NavItemEspot } from '@/components/content/Header/parts/NavItemEspot';
import { HeaderSearch } from '@/components/content/Header/parts/Search';
import { headerNavBarContainerSX } from '@/components/content/Header/styles/navBar/container';
import { useNavigation } from '@/data/Navigation';
import { ID } from '@/data/types/Basic';
import { ContentItemProperties } from '@/data/types/Slot';
import { Container, Paper, Stack, useMediaQuery, useTheme } from '@mui/material';
import { FC } from 'react';

export const DivalHeaderNavBar: FC<{ navEspots: ContentItemProperties[]; id: ID }> = ({
	navEspots,
	id,
}) => {
	const { allCategories } = useNavigation();
	const { breakpoints } = useTheme();
	const isDesktop = useMediaQuery(breakpoints.up('md'));
	return (
		<Paper sx={headerNavBarContainerSX}>
			<Container>
				<Stack direction="row" spacing={2} alignItems="center">
					{isDesktop && allCategories ? (
						<DivalMegaMenu tree={allCategories.children} label={allCategories.label} />
					) : null}
					<HeaderSearch />
					{isDesktop
						? navEspots.map((properties) => (
								<NavItemEspot
									key={properties.emsName}
									id={`${id}-${properties.emsName}`}
									properties={properties}
								/>
							))
						: null}
				</Stack>
			</Container>
		</Paper>
	);
};
