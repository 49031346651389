import { ProgressIndicator } from '@/components/blocks/ProgressIndicator';
import { RenderContent } from '@/components/blocks/RenderContent';
import { divalHeaderNavBarItemSX } from '@/components/content/Header/styles/navBar/divalItem';
import { navItemEspotSX } from '@/components/content/Header/styles/navBar/navItemEspot';
import { useContentRecommendation } from '@/data/Content/ContentRecommendation';
import { useNextRouter } from '@/data/Content/_NextRouter';
import { ID } from '@/data/types/Basic';
import { WidgetProperties } from '@/data/types/Slot';
import { Switch } from '@/utils/switch';
import { Button, ClickAwayListener, Stack, Tooltip } from '@mui/material';
import React, { FC, useCallback, useEffect } from 'react';

const emptyProperties = {} as WidgetProperties;

export const NavItemEspot: FC<{ id: ID; properties?: WidgetProperties }> = ({
	id: _id,
	properties = emptyProperties,
}) => {
	const { emsName = '' } = properties;
	const { data, loading, title } = useContentRecommendation(emsName);
	const router = useNextRouter();
	const [open, setOpen] = React.useState(false);
	const handleToolTip = useCallback(
		(action?: string) => () =>
			setOpen((open) =>
				Switch(action)
					.case('open', () => true)
					.case('close', () => false)
					.defaultTo(() => !open)
			),
		[]
	);
	useEffect(() => {
		if (!router.asPath) return;
		handleToolTip('close')();
	}, [handleToolTip, router.asPath]);

	return loading ? (
		<ProgressIndicator />
	) : title && data ? (
		<ClickAwayListener onClickAway={handleToolTip('close')}>
			<Stack onMouseLeave={handleToolTip('close')}>
				<Tooltip
					placement="bottom-start"
					open={open}
					onClose={handleToolTip('close')}
					disableFocusListener
					disableHoverListener
					disableTouchListener
					componentsProps={{
						tooltip: {
							sx: navItemEspotSX,
						},
					}}
					title={data?.map((content) => (
						<RenderContent key={`${content.id}${content.contentId}`} content={content} />
					))}
				>
					<Button
						onMouseEnter={handleToolTip('open')}
						onTouchStart={handleToolTip('close')}
						sx={divalHeaderNavBarItemSX({ isActive: open })}
					>
						{title?.map((content) => (
							<RenderContent key={`${content.id}${content.contentId}`} content={content} />
						))}
					</Button>
				</Tooltip>
			</Stack>
		</ClickAwayListener>
	) : null;
};
