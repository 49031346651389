import SvgIcon from '@mui/material/SvgIcon';

export const PersonIcon = (props: any) => (
	<SvgIcon {...props}>
		<path d="M0,24H3.42V17.75A7.61,7.61,0,0,0,0,24Z" />
		<path d="M16.48,16.25a5.15,5.15,0,0,1-4.55,2.18,5.17,5.17,0,0,1-4.55-2.18,10,10,0,0,0-1.54.33V24H18V16.58a10.17,10.17,0,0,0-1.55-.33Z" />
		<polygon points="17.81 9.53 17.81 9.54 17.82 9.53 17.81 9.53" />
		<path d="M10,16.17a4.81,4.81,0,0,0,3.93,0,6.2,6.2,0,0,0,2.63-2.34,8.46,8.46,0,0,0,1.28-4.29,46.19,46.19,0,0,1-5.88.37,44.54,44.54,0,0,1-5.88-.38,8.6,8.6,0,0,0,1.28,4.3A6.1,6.1,0,0,0,10,16.17Z" />
		<path d="M18.35,6.8a6.43,6.43,0,0,0-3.53-5.73l-.27,1.52-.32,1.8a.42.42,0,0,1-.49.34.41.41,0,0,1-.34-.48l.36-2.06L14.05.53A.76.76,0,0,0,13.32,0H10.55a.78.78,0,0,0-.73.53l.3,1.66.36,2.05a.42.42,0,0,1-.33.5.43.43,0,0,1-.5-.33v0l-.32-1.8L9.07,1.07A6.41,6.41,0,0,0,5.52,6.8a.91.91,0,0,0-.83.91,1,1,0,0,0,.91.91l.46.06a41.15,41.15,0,0,0,5.87.39,42.94,42.94,0,0,0,5.89-.38l.45-.07a1,1,0,0,0,.91-.91A.92.92,0,0,0,18.35,6.8Z" />
		<path d="M20.45,17.76V24h3.42A7.59,7.59,0,0,0,20.45,17.76Z" />
	</SvgIcon>
);
