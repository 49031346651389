import { SxProps } from '@mui/material';

export const divalMegaMenuSecondLevelContainerSX: SxProps = {
	p: 2,
	width: '601px',
	ml: '-1px',
	boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 4px 2px, rgba(0, 0, 0, 0.03) 0px 2px 1px 0px;',
	backgroundColor: '#f2f2f2',
	borderLeftWidth: '1px',
	borderLeftStyle: 'solid',
	borderLeftColor: 'background.main',
	borderBottomWidth: '1px',
	borderBottomStyle: 'solid',
	borderBottomColor: 'background.main',
	borderRightWidth: '1px',
	borderRightStyle: 'solid',
	borderRightColor: 'background.main',
};
