/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { Linkable } from '@/components/blocks/Linkable';
import { headerMiniCartItemSX } from '@/components/content/Header/styles/miniCart/item';
import { headerMiniCartMenuSX } from '@/components/content/Header/styles/miniCart/menu';
import { headerMiniCartMoreItemsSX } from '@/components/content/Header/styles/miniCart/moreItems';
import { OrderItemTable } from '@/components/content/OrderItemTable';
import { useCart } from '@/data/Content/Cart';
import { useNextRouter } from '@/data/Content/_NextRouter';
import { useLocalization } from '@/data/Localization';
import { useSettings } from '@/data/Settings';
import { combineSX } from '@/utils/combineSX';
import { formatNumberValue } from '@/utils/formatNumberValue';
import { formatPrice } from '@/utils/formatPrice';
import { Divider, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FC, useEffect, useMemo } from 'react';

export const HeaderMiniCartDropMenu: FC = () => {
	const router = useNextRouter();
	const { settings } = useSettings();
	const CartLabels = useLocalization('MiniCart');
	const RouteLabels = useLocalization('Routes');
	const { orderItems, data: order, count, getCount } = useCart();
	const locale = useMemo(() => router.locale ?? router.defaultLocale, [router]);
	const totalPrice = order?.totalProductPrice ?? '0.00';
	const currency = order?.totalProductPriceCurrency ?? settings.defaultCurrency;
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const MAX_ROWS = isMobile ? 2 : 3;
	const { firstNRows, firstNRowsCount } = useMemo(() => {
		const firstNRows = orderItems?.slice(0, MAX_ROWS);
		const firstNRowsCount = getCount(firstNRows);
		return { firstNRows, firstNRowsCount };
	}, [MAX_ROWS, getCount, orderItems]);

	useEffect(() => {
		const element = document.querySelector('#MiniCartWrapper .MuiTooltip-tooltip');
		if (element) {
			if (firstNRows?.length > 0) {
				element.setAttribute('style', 'display: block');
			} else {
				element.setAttribute('style', 'display: none');
			}
			if (isMobile) {
				element.setAttribute('style', 'display: none');
			}
		}
	}, [firstNRows, isMobile]);

	return (
		<Stack sx={headerMiniCartMenuSX} spacing={1} divider={<Divider />}>
			{firstNRows?.length > 0 ? (
				<Stack divider={<Divider />}>
					<Typography variant="h6" sx={headerMiniCartItemSX}>
						{CartLabels.Title.t()}
					</Typography>
					<Stack>
						<OrderItemTable orderItems={firstNRows} orderId={order?.orderId} variant="mini" />
						{count > firstNRowsCount ? (
							<Typography sx={headerMiniCartMoreItemsSX} align="right">
								{CartLabels.AndNMore.t({ count: count - firstNRowsCount })}
							</Typography>
						) : null}
					</Stack>
					<Stack direction="row" justifyContent="space-between" alignItems="center" py={1}>
						<Typography variant="body1" sx={headerMiniCartItemSX} align="center">
							{CartLabels.Subtotal.t({
								count: formatNumberValue({
									value: count,
									precision: 0,
									decimalSeparator: '.',
									thousandSeparator: ',',
								}),
							})}
						</Typography>
						<Typography variant="body1" sx={headerMiniCartItemSX} align="center">
							{formatPrice(locale, currency, totalPrice)}
						</Typography>
					</Stack>
					<Stack
						direction="row"
						spacing={1}
						justifyContent="center"
						sx={combineSX([headerMiniCartItemSX, { py: 2 }])}
					>
						<Linkable
							href={RouteLabels.Cart.route.t()}
							type="button"
							variant="contained"
							id="mini-cart-view-full-cart-button"
							data-testid="mini-cart-view-full-cart-button"
						>
							{CartLabels.Actions.Cart.t()}
						</Linkable>
					</Stack>
				</Stack>
			) : (
				<Stack spacing={1} alignItems="center" sx={headerMiniCartItemSX}>
					<Typography variant="body1" align="center">
						{CartLabels.Empty.t()}
					</Typography>
				</Stack>
			)}
		</Stack>
	);
};
